<template>
  <a-card class="card" :bordered="false">
    <a-tabs :active-key="mainTabKey" @change="handleTabChange">
      <a-tab-pane tab="利润明细" key="profit">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpan" class="range-date">
                <a-form-item label="录单时间">
                  <a-range-picker @change="onDateChange" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="委托客户">
                  <a-select
                    showSearch
                    label-in-value
                    allowClear
                    placeholder="请选择委托客户"
                    style="width: 100%"
                    :value="customerInfo"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @search="getDepartmentCustomer"
                    @change="changeCustomerInfo"
                  >
                    <template v-if="fetching" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                    <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="客户业务编号">
                  <a-input v-model="queryParam['%customer_num']" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="订单编号">
                  <a-input v-model="queryParam['%serial_num']" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="报价编号">
                  <a-input v-model="queryParam['%quota_num']" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="报关单号">
                  <a-input v-model="queryParam['%declare_num']" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="合约号">
                  <a-input v-model="queryParam['%deal_num']" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="主单号">
                  <a-input v-model="queryParam['%order_num']" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="箱号">
                  <a-input v-model="queryParam['%box_info']" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-model-item label="订单类型">
                  <a-select
                    allowClear
                    placeholder="请选择订单类型"
                    style="width: 100%"
                    v-model="queryParam['order_kind']"
                  >
                    <a-select-option v-for="op in orderKindOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="业务类型">
                  <a-select showSearch style="width: 100%"
                            allowClear v-model="queryParam.business_Type">
                    <a-select-option :key="0">保税仓储</a-select-option>
                    <a-select-option :key="1">海运进口</a-select-option>
                    <a-select-option :key="2">海运出口</a-select-option>
                    <a-select-option :key="3">空运进口</a-select-option>
                    <a-select-option :key="4">空运出口</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="table-operator">
          <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
          <a-button type="primary" @click="handleExport('export_profit_data')">导出</a-button>
          <a-popover v-model="popVisible" title="选择月份" trigger="click">
            <a slot="content">
              <p><a-month-picker v-model="exportDate" style="width: 200px" format="YYYY/MM" placeholder="请选择月份" /></p>
              <p style="text-align: right"><a-button type="primary" @click="handleStaticsExport">确定</a-button></p>
            </a>
            <a-button type="primary">导出月度统计表</a-button>
            <!-- 月度统计表导出功能没有页面可以放，暂时放在利润表中 -->
          </a-popover>
          <a-popover v-model="yearVisible" title="选择年份" trigger="click">
            <a slot="content">
              <p>
                <a-date-picker
                  :value="exportYear"
                  mode="year"
                  style="width: 200px"
                  format="YYYY"
                  :open="yearChoose"
                  placeholder="请选择年份"
                  @panelChange="chooseYear"
                  @openChange="handleYearOpen"
                />
              </p>
              <p style="text-align: right"><a-button type="primary" @click="handleYearStaticsExport">确定</a-button></p>
            </a>
            <a-button type="primary">导出年度统计表</a-button>
            <!-- 月度统计表导出功能没有页面可以放，暂时放在利润表中 -->
          </a-popover>
        </div>
        <s-table
          ref="table"
          size="default"
          :rowKey="
            record => {
              return record.quota_num
            }
          "
          :columns="columns"
          :data="loadData"
          :alert="{
            show: true,
            msg: showTotal
          }"
          :scroll="{ x: 'max-content' }"
          :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }">
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
        </s-table>
      </a-tab-pane>
      <a-tab-pane tab="开票汇总" key="invoice">
        <invoice-month :pageType="pageType" />
      </a-tab-pane>
      <a-tab-pane tab="客户利润总表" key="customerProfit">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpan" class="range-date">
                <a-form-item label="时间">
                  <a-range-picker :defaultValue="[queryParam.start_date, queryParam.end_date]" @change="dateChange" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="客户">
                  <a-select
                    showSearch
                    label-in-value
                    allowClear
                    style="width: 100%"
                    :value="customerInfo"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @search="getDepartmentCustomer"
                    @change="changeCustomerInfo"
                  >
                    <template v-if="fetching" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                    <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="table-operator">
          <a-button type="query" icon="search" @click="queryList">查询</a-button>
          <a-button type="primary" icon="download" @click="exportSheet">导出</a-button>
          <a-button type="primary" icon="pie-chart" @click="queryStatisticsChart">统计图</a-button>
        </div>
        <a-table
          size="middle"
          :rowKey="function(record) { return getRandomCode(8) }"
          :scroll="{ x: 'max-content' }"
          :columns="customerProfitColumns"
          :data-source="customerProfitData"
          :pagination="pagination"
          @change="handleChangeTable"
        >
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>

    <a-modal
      title="统计图"
      :visible="chartVisible"
      :width="1600"
      :footer="null"
      @cancel="chartVisible = false"
    >
      <a-row :gutter="24">
        <a-col :span="24">
          <a-card title="客户统计图" :bordered="false">
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item label="时间">
                  <a-range-picker :defaultValue="[queryParam2['start_date'], queryParam2['end_date']]" @change="onDateChange2"/>
                </a-form-item>
              </a-col>
            </a-row>
            <div ref="chart1" style="width: 1500px; height: 500px;"></div>
          </a-card>
        </a-col>
        <a-col :span="24">
          <a-card title="去税收入成本统计" :bordered="false">
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item label="时间">
                  <a-range-picker :defaultValue="[queryUntaxParam['start_date'], queryUntaxParam['end_date']]" @change="onDateChangeUntax"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <div ref="chartUntaxCharge" style="width: 700px; height: 500px;"></div>
              </a-col>
              <a-col :span="12">
                <div ref="chartUntaxPay" style="width: 700px; height: 500px;"></div>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-col :span="24">
          <a-card title="订单分类统计" :bordered="false">
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item label="时间">
                  <a-range-picker :defaultValue="[queryParam2['start_date'], queryParam2['end_date']]" @change="onDateChangeOrder"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <div ref="chartOrder" style="width: 700px; height: 500px;"></div>
              </a-col>
              <a-col :span="12">
                <div ref="chartOrderProfit" style="width: 700px; height: 500px;"></div>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { downloadExcel, getCustomer } from '@/api/common'
import { filterOption, getRandomCode } from '@/utils/util'
import {
  getItmsFinancePage,
  exportItmsFinance,
  exportItmsStatics,
  listCustomerProfit,
  exportCustomerProfitSheet,
  statisticsTop20AuthAgencyData,
  handleStatisticsByBusinessType,
  handleUntaxChargeStatisticsByBusinessType,
  handleUntaxPayStatisticsByBusinessType
} from '@/api/itms'
import debounce from 'lodash/debounce';
import invoiceMonth from '@/views/fms/finance/InvoiceMonth'
import moment from 'moment'

export default {
  components: {
    STable,
    invoiceMonth
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      pageType: 2,
      queryParam: {
        start_date: moment(new Date()).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).endOf('month').format('YYYY-MM-DD HH:mm:ss')
      },
      showTotal: '',
      customerOps: [],
      customerInfo: undefined,
      fetching: false,
      popVisible: false,
      yearVisible: false,
      exportDate: null,
      yearChoose: false,
      exportYear: null,
      mainTabKey: 'profit',
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '订单编号',
          dataIndex: 'quota_num',
          width: 150
        },
        {
          title: '箱型箱量',
          dataIndex: 'box_info',
          width: 120
        },
        {
          title: '业务类型',
          dataIndex: 'business_type',
          width: 120,
          customRender: (text) => {
            switch (text) {
              case 0:
                return '保税仓储';
              case 1:
                return '海运进口';
              case 2:
                return '海运出口';
              case 3:
                return '空运进口';
              case 4:
                return '空运出口';
            }
          }
        },
        {
          title: '应收费用状态',
          dataIndex: 'charge_status',
          customRender: text => {
            return this.GLOBAL.feeStatusMaps[text] ? this.GLOBAL.feeStatusMaps[text].name : ''
          },
          width: 110
        },
        {
          title: '应付费用状态',
          dataIndex: 'pay_status',
          customRender: text => {
            return this.GLOBAL.feeStatusMaps[text] ? this.GLOBAL.feeStatusMaps[text].name : ''
          },
          width: 110
        },
        {
          title: '委托客户',
          dataIndex: 'agency_name',
          width: 200
        },
        {
          title: '利润',
          dataIndex: 'profit',
          width: 100
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 200
        }
      ],
      selectedRowKeys: [],
      confirmLoading: false,
      loadData: parameter => {
        return getItmsFinancePage('profit_list', Object.assign(parameter, this.queryParam)).then(res => {
          this.showTotal = '箱型合计：' + res.total_row;
          return res
        })
      },
      orderKindOps: [
        { name: '国贸', value: 1 },
        { name: '奥货', value: 2 },
        { name: '订舱', value: 3 }
      ],
      customerProfitColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '委托客户',
          dataIndex: 'auth_agency_name'
        },
        {
          title: '业务类型',
          dataIndex: 'business_type_str'
        },
        {
          title: '订单数',
          dataIndex: 'num'
        },
        {
          title: '总应收金额',
          dataIndex: 'charge_total'
        },
        {
          title: '总成本金额',
          dataIndex: 'pay_total'
        },
        {
          title: '利润',
          dataIndex: 'profit'
        },
        {
          title: '利润百分比',
          dataIndex: 'profit_ratio',
          customRender: (text) => {
            return text ? `${text} %` : '0 %'
          }
        }
      ],
      customerProfitData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      chartVisible: false,
      queryParam2: {
        start_date: moment(new Date()).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).endOf('month').format('YYYY-MM-DD HH:mm:ss')
      },
      queryUntaxParam: {
        start_date: moment(new Date()).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).endOf('month').format('YYYY-MM-DD HH:mm:ss')
      },
      chart1Option: {},
      orderPieChartOption: {},
      orderProfitPieChartOption: {}
    }
  },
  methods: {
    filterOption,
    getRandomCode,
    // 查看排名前20的客户金额统计图
    queryStatisticsChart() {
      this.chartVisible = true
      this.loadChart1()
      this.loadOrderStatistic()
    },
    loadOrderStatistic() {
      handleStatisticsByBusinessType(this.queryParam2).then(res => {
        const dataSource = []
        const profitDateSource = []
        res.forEach(item => {
          dataSource.push({ value: item.charge_total,
          name: item.business_type_str,
          label: { show: true,
          position: 'outside',
          formatter: function(params) {
            return item.business_type_str + '\n应收金额：' + item.charge_total + '\n占比：' + item.charge_ratio;
          } } })

          profitDateSource.push({ value: item.profit,
          name: item.business_type_str,
          label: { show: true,
          position: 'outside',
          formatter: function(params) {
            return item.business_type_str + '\n利润：' + item.profit + '\n利润率：' + item.profit_ratio + '%' + '\n利润占比：' + item.profit_percent + '%';
          } } })
        })
        this.orderPieChartOption = {
          tooltip: { trigger: 'item', formatter: '{b} : {c} ({d}%)' },
          legend: { orient: 'vertical', left: 'left' },
          toolbox: { feature: { saveAsImage: {} } },
          series: [
            {
              type: 'pie',
              radius: '30%',
              data: dataSource,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        this.orderProfitPieChartOption = {
          tooltip: { trigger: 'item', formatter: '{b} : {c} ({d}%)' },
          legend: { orient: 'vertical', left: 'left' },
          toolbox: { feature: { saveAsImage: {} } },
          series: [
            {
              type: 'pie',
              radius: '30%',
              data: profitDateSource,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        this.drawChartOrder()
        this.drawChartOrderProfit()
      })
    },
    loadChart1() {
      statisticsTop20AuthAgencyData(this.queryParam2).then(res => {
        const authAgencyData = []
        const orderNumData = []
        const chargeTotalData = []
        const profitData = []
        const profitRatioData = []
        res.forEach(item => {
          authAgencyData.push(item.auth_agency_name)
          orderNumData.push(item.num)
          chargeTotalData.push(item.charge_total)
          profitData.push(item.profit)
          profitRatioData.push(item.profit_ratio)
        })
        this.chart1Option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['订单数', '应收', '利润', '利润百分比']
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: [
            {
              type: 'category',
              axisTick: { show: false },
              data: authAgencyData,
              axisLabel: { interval: 0, rotate: -20 }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '订单数',
              type: 'bar',
              barGap: 0,
              emphasis: {
                focus: 'series'
              },
              data: orderNumData
            },
            {
              name: '应收',
              type: 'bar',
              barGap: 0,
              emphasis: {
                focus: 'series'
              },
              data: chargeTotalData
            },
            {
              name: '利润',
              type: 'bar',
              barGap: 0,
              emphasis: {
                focus: 'series'
              },
              data: profitData
            },
            {
              name: '利润百分比',
              type: 'bar',
              barGap: 0,
              emphasis: {
                focus: 'series'
              },
              data: profitRatioData
            }
          ]
        }
        this.drawChart1()
      })
    },
    drawChart1() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.chart1)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.chart1)
        }
        myChart.setOption(this.chart1Option)
      }, 500);
    },
    drawChartOrder() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.chartOrder)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.chartOrder)
        }
        myChart.setOption(this.orderPieChartOption)
      }, 500);
    },
    drawChartOrderProfit() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.chartOrderProfit)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.chartOrderProfit)
        }
        myChart.setOption(this.orderProfitPieChartOption)
      }, 500);
    },
    loadUntaxStatistic() {
      handleUntaxChargeStatisticsByBusinessType(this.queryUntaxParam).then(res => {
        const dataSource = []
        res.forEach(item => {
          dataSource.push({ value: item.charge_total,
          name: item.business_type_str,
          label: { show: true,
          position: 'outside',
          formatter: function(params) {
            return item.business_type_str + '\n应收金额：' + item.charge_total + '\n占比：' + item.charge_ratio;
          } } })
        })
        this.untaxChargePieChartOption = {
          tooltip: { trigger: 'item', formatter: '{b} : {c} ({d}%)' },
          legend: { orient: 'vertical', left: 'left' },
          toolbox: { feature: { saveAsImage: {} } },
          series: [
            {
              type: 'pie',
              radius: '30%',
              data: dataSource,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        this.drawChartUntaxCharge()
      })
      handleUntaxPayStatisticsByBusinessType(this.queryUntaxParam).then(res => {
        const dataSource = []
        res.forEach(item => {
          dataSource.push({ value: item.pay_total,
          name: item.business_type_str,
          label: { show: true,
          position: 'outside',
          formatter: function(params) {
            return item.business_type_str + '\n成本：' + item.pay_total;
          } } })
        })
        this.untaxPayPieChartOption = {
          tooltip: { trigger: 'item', formatter: '{b} : {c} ({d}%)' },
          legend: { orient: 'vertical', left: 'left' },
          toolbox: { feature: { saveAsImage: {} } },
          series: [
            {
              type: 'pie',
              radius: '30%',
              data: dataSource,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        this.drawChartUntaxPay()
      })
    },
    drawChartUntaxCharge() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.chartUntaxCharge)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.chartUntaxCharge)
        }
        myChart.setOption(this.untaxChargePieChartOption)
      }, 500);
    },
    drawChartUntaxPay() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.chartUntaxPay)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.chartUntaxPay)
        }
        myChart.setOption(this.untaxPayPieChartOption)
      }, 500);
    },
    onDateChange2(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam2['start_date'] = startDate
        this.queryParam2['end_date'] = endDate
      } else {
        this.queryParam2['start_date'] = null
        this.queryParam2['end_date'] = null
      }
      this.loadChart1()
    },
    onDateChangeOrder(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam2['start_date'] = startDate
        this.queryParam2['end_date'] = endDate
      } else {
        this.queryParam2['start_date'] = null
        this.queryParam2['end_date'] = null
      }
      this.loadOrderStatistic()
    },
    onDateChangeUntax(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryUntaxParam['start_date'] = startDate
        this.queryUntaxParam['end_date'] = endDate
      } else {
        this.queryUntaxParam['start_date'] = null
        this.queryUntaxParam['end_date'] = null
      }
      this.loadUntaxStatistic()
    },
    // 查询客户利润总表
    loadCustomerProfitData() {
      listCustomerProfit(this.queryParam).then(res => {
        this.pagination.total = res.total
        this.customerProfitData = res.list
      })
    },
    queryList() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.queryParam['page'] = 1
      this.queryParam['pageSize'] = 10
      this.loadCustomerProfitData()
    },
    handleChangeTable(page) {
      this.pagination.current = page.current
      this.pagination.pageSize = page.pageSize
      this.queryParam['page'] = page.current
      this.queryParam['pageSize'] = page.pageSize
      this.loadCustomerProfitData()
    },
    // 导出客户利润总表
    exportSheet() {
      this.$confirm({
        title: '确认提示',
        content: '确定要导出吗？',
        onOk: () => {
          exportCustomerProfitSheet(this.queryParam).then(res => {
            if (res !== null) {
              const fileInfo = { fileName: res }
              downloadExcel(fileInfo).then(downRes => {
                const data = downRes.data
                let fileName = ''
                const contentDisposition = downRes.headers['content-disposition']
                if (contentDisposition) {
                  fileName = decodeURI(escape(contentDisposition.split('=')[1]))
                }
                const url = window.URL.createObjectURL(
                  new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                )
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              })
            }
          })
        }
      })
    },
    dateChange(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['start_date'] = startDate
        this.queryParam['end_date'] = endDate
      } else {
        this.queryParam['start_date'] = null
        this.queryParam['end_date'] = null
      }
    },
    handleTabChange(key) {
      this.mainTabKey = key
      if (key === 'customerProfit') {
        // 查询客户利润总表
        this.queryList()
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    onDateChange(date, dateString) {
      const moments = []
      if (date[0]) {
        moments.push(date[0].startOf('day').valueOf())
        moments.push(date[1].endOf('day').valueOf())
      }
      this.queryParam['@created_at'] = moments
    },
    chooseYear(date) {
      this.exportYear = date
      this.yearChoose = false
    },
    handleYearOpen(status) {
      if (status) {
        this.yearChoose = true
      } else {
        this.yearChoose = false
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 2,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        this.customerInfo = undefined
        this.queryParam['auth_agency'] = null
      } else {
        this.customerInfo = value
        this.queryParam['auth_agency'] = value.key
      }
      this.customerOps = []
      this.fetching = false
    },
    handleStaticsExport() {
      if (this.exportDate) {
        const year = this.exportDate.year()
        const month = this.exportDate.month()
        this.handleExport('export_data', {
          year: year,
          month: month + 1
        })
        this.popVisible = false
      } else {
        this.$message.warning('请选择导出月份')
      }
    },
    handleYearStaticsExport() {
      if (this.exportYear) {
        const year = this.exportYear.year()
        this.handleExport('export_year', {
          year: year
        })
        this.yearVisible = false
      } else {
        this.$message.warning('请选择导出年份')
      }
    },
    handleExport(type, queryParam) {
      let func = exportItmsFinance // 导出利润表
      if (type === 'export_data' || type === 'export_year') {
        // 导出月度统计表
        func = exportItmsStatics
      }
      func(type, queryParam || this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    }
  }
}
</script>
